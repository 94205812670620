exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-demo-page-js": () => import("./../../../src/pages/demo-page.js" /* webpackChunkName: "component---src-pages-demo-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-plans-js": () => import("./../../../src/pages/pricing-plans.js" /* webpackChunkName: "component---src-pages-pricing-plans-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-recops-guide-js": () => import("./../../../src/pages/recops-guide.js" /* webpackChunkName: "component---src-pages-recops-guide-js" */),
  "component---src-pages-successful-request-js": () => import("./../../../src/pages/successful-request.js" /* webpackChunkName: "component---src-pages-successful-request-js" */),
  "component---src-pages-talk-to-us-js": () => import("./../../../src/pages/talk-to-us.js" /* webpackChunkName: "component---src-pages-talk-to-us-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-testimonial-page-js": () => import("./../../../src/pages/testimonial-page.js" /* webpackChunkName: "component---src-pages-testimonial-page-js" */),
  "component---src-pages-trial-page-js": () => import("./../../../src/pages/trial-page.js" /* webpackChunkName: "component---src-pages-trial-page-js" */),
  "component---src-pages-why-hiringmaster-js": () => import("./../../../src/pages/why-hiringmaster.js" /* webpackChunkName: "component---src-pages-why-hiringmaster-js" */),
  "component---src-templates-blogpost-js": () => import("./../../../src/templates/Blogpost.js" /* webpackChunkName: "component---src-templates-blogpost-js" */)
}

